import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { EditUserComponent } from "../edit-user/edit-user.component";

@Component({
  selector: "users-list",
  templateUrl: "./users-list.component.html",
  styleUrls: ["./users-list.component.css"],
})
export class UsersListComponent implements OnInit {
  displayedColumns: string[] = ["firstname", "lastname", "email", "actions"];
  usersDataSource = [
    {
      firstname: "Khushwant",
      lastname: "Yadav",
      email: "khushwantyadav@kraptr.com",
    },
  ];
  constructor(
    public dialogRef: MatDialogRef<UsersListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) {}

  ngOnInit() {}
  onCancel() {
    this.dialogRef.close();
  }
  openEditUserDialog() {
    this.dialog.open(EditUserComponent, {
      height: "400px",
      width: "1000px",
    });
  }
}
