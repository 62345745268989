import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AppService {
  backendHostName = "http://ec2-35-154-111-121.ap-south-1.compute.amazonaws.com:8080";
  backendAPIEndpoint = "/api";
  version = "/v1";
  baseURL = this.backendHostName + this.backendAPIEndpoint + this.version;

  constructor(private http: HttpClient) { }

  generateToken(body: any) {
    return this.http
      .post(this.baseURL + "/auth/token", body)
      .pipe(catchError(this.handleError));
  }

  handleError(error: any) {
    let errorCode = "";
    if (error.error instanceof ErrorEvent) {
    } else {
      errorCode = `${error.status}`;
    }
    return throwError(errorCode);
  }
}
