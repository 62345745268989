import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppService } from "../app.service";

@Component({
  selector: "app-token-callback",
  templateUrl: "./token-callback.component.html",
  styleUrls: ["./token-callback.component.css"],
})
export class TokenCallbackComponent implements OnInit {
  constructor(private appService: AppService, private router: Router) {}

  async ngOnInit() {
    let query = window.location.search.substring(1);
    let id_token = this.parse_query_string(query).id_token;
    let token: any = await this.generateToken(id_token);

    localStorage.setItem("accessToken", token.token.access_token);
    this.router.navigate([""]);
  }
  parse_query_string(query: string) {
    let vars = query.split("&");
    let query_string: any = {};
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      var key = decodeURIComponent(pair[0]);
      var value = decodeURIComponent(pair[1]);
      // If first entry with this name
      if (typeof query_string[key] === "undefined") {
        query_string[key] = decodeURIComponent(value);
        // If second entry with this name
      } else if (typeof query_string[key] === "string") {
        var arr = [query_string[key], decodeURIComponent(value)];
        query_string[key] = arr;
        // If third or later entry with this name
      } else {
        query_string[key].push(decodeURIComponent(value));
      }
    }
    return query_string;
  }
  generateToken(id_token: string) {
    let body = {
      idtoken: id_token,
      apikey: "sss",
      apikeysecret: "sss",
    };
    return new Promise((resolve) => {
      this.appService.generateToken(body).subscribe((res) => {
        resolve(res);
      });
    });
  }
}
