import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "edit-user",
  templateUrl: "./edit-user.component.html",
  styleUrls: ["./edit-user.component.css"],
})
export class EditUserComponent implements OnInit {
  displayedColumns: string[] = ["firstname", "lastname", "email", "actions"];
  usersDataSource = [
    {
      firstname: "Khushwant",
      lastname: "Yadav",
      email: "khushwantyadav@kraptr.com",
    },
  ];
  constructor(
    public dialogRef: MatDialogRef<EditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}
  onCancel() {
    this.dialogRef.close();
  }
}
