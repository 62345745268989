import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
} from "@angular/router";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  uid = "";
  constructor(private router: Router, private rout: ActivatedRoute) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // const currentUser = this.authenticationService.currentUserValue;
    const token = localStorage.getItem("accessToken");
    // const currentTime = Math.floor(Date.now());

    if (token) {
      // logged in so return true
      //   alert("all good." + token);
      return true;
    } else {
      window.location.href = "http://sso.dreamhighandfly.com/auth?apikey=ddd";
      return false;
    }
  }
}
