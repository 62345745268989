import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AddUserComponent } from "../components/users/add-user/add-user.component";
import { UsersListComponent } from "../components/users/users-list/users-list.component";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  constructor(public dialog: MatDialog, private router: Router) { }

  ngOnInit() { }
  openDialog() {
    this.dialog.open(AddUserComponent);
  }
  openUsersListDialog() {
    this.dialog.open(UsersListComponent, {
      height: "400px",
      width: "1000px",
    });
  }
  openApplication(application) {
    switch (application) {
      case 'SSA':
        this.router.navigate([]).then(result => { window.open("http://ssa.dreamhighandfly.com", '_blank'); });
        break;
      case 'DMS':
        this.router.navigate([]).then(result => { window.open("http://dms.dreamhighandfly.com", '_blank'); });
        break;

    }
  }
}
